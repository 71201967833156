body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.brand {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.brand img {
  width: 150px;
  height: 80px;
}
.summary-form {
  background-color: #f0f4f5;
  border-radius: 4px;
}

.confirmation {
  margin: 100px;
}
.summary-header {
  font-size: 16px;
}
.summary-field {
  margin-bottom: 16px;
}
.otpModal {
  position: relative;
  padding: 40px;
  border-radius: 12px;
}
.summary-label {
  margin-bottom: 4px;
  color: #7e848d;
  font-size: 12px;
  font-weight: bold;
}

.requestOTPButton {
  margin-right: 30px;
}

.errorForm {
  margin: 100px;
}

.centerize {
  display: block;
  margin: 10px;
  text-align: center;
}

.loadingSpinner {
  margin: 150px;
}

.successForm {
  margin: 100px;
}

.groupPane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* toegevoegd */
.tabular.menu {
  display: flex;
  justify-content: center;
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.modal,
.ui.active.modal {
  margin: 0 auto!important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
}

input.modalInput {
  max-width: 75%;
}

